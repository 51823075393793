// import geFlag from '../../storage/app/public/flag/ge.png';
// import usaFlag from '../../storage/app/public/flag/FlagUSA.png';

function openNav() {
    var mobNavOpen = document.getElementById("myNav");
    var closeBtn = document.getElementById('closebtn');
    var content = document.getElementById('overlay-content');
    mobNavOpen.style.width = "100%";
    mobNavOpen.style.transform = "translate(0, 0%)";
    mobNavOpen.style.Webkittransform = "translate(0, 0%)";
    content.style.opacity = "1";
    content.style.transition = "3s";
    mobNavOpen.style.transition = "0.6s";
    closeBtn.style.opacity = "1";
    closeBtn.style.transition = "6s";
}

function closeNav() {
    var mobNavOpen = document.getElementById("myNav");
    var closeBtn = document.getElementById('closebtn');
    var content = document.getElementById('overlay-content');
    mobNavOpen.style.width = "0%";
    mobNavOpen.style.transform = "translate(-100%, 0%)";
    content.style.opacity = "0";
    content.style.transition = "0.1s";
    mobNavOpen.style.transition = "0.6s";
    closeBtn.style.opacity = "0";
    closeBtn.style.transition = "1s";
}

// var langChanger = document.getElementById('flag');

// langChanger.addEventListener('click', function () {
//     if ($('#flag').attr('src') === window.location.origin + '/storage/flag/ge.png') {
//         $('#flag').attr('src', window.location.origin + '/storage/flag/FlagUSA.png');
//     } else {
//         $('#flag').attr('src', window.location.origin + '/storage/flag/ge.png');
//     }
// });

window.openNav = openNav;
window.closeNav = closeNav;
